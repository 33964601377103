import "./App.css";
import TermsOfUse from "./components/termsOfUse";
import PrivacyPolicy from "./components/privacyPolicy";
import Home from "./components/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  const tabs = [
    {
      name: "Who we are",
      content: [
        {
          size: "medium",
          title: "Our Story",
          description:
            "Founded in 2015, we started with a vision to simplify financial technology for businesses of all sizes.",
          color: "#116466",
        },
        {
          size: "large",
          title: "Mission & Values",
          description:
            "Our mission is to empower financial institutions and businesses with cutting-edge solutions that drive growth and efficiency.",
          color: "#116466",
        },
        {
          size: "small",
          title: "Global Reach",
          description: "Operating in over 10 countries.",
          color: "#116466",
        },
        {
          size: "small",
          title: "Team Size",
          description: "Over 150 talented individuals.",
          color: "#116466",
        },
        {
          size: "medium",
          title: "Leadership Team",
          description:
            "Led by industry experts with decades of combined experience in software engineering and finance.",
          color: "#116466",
        },
      ],
    },
    {
      name: "Our Services",
      content: [
        {
          size: "small",
          title: "Consulting",
          description: "Fintech strategy consulting.",
          color: "#D1E8E2",
        },
        {
          size: "medium",
          title: "Cloud & DevOps",
          description:
            "Our cloud solutions and DevOps practices ensure reliable deployments and rapid scaling.",
          color: "#D1E8E2",
        },
        {
          size: "small",
          title: "Custom Development",
          description: "Tailored fintech software solutions.",
          color: "#D1E8E2",
        },
        {
          size: "large",
          title: "Enterprise Solutions",
          description:
            "Comprehensive fintech solutions for large financial institutions, focusing on scalability and security.",
          color: "#D1E8E2",
        },
      ],
    },
  ];

  return (
    <Router>
      <Header />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termsOfUse" element={<TermsOfUse />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
