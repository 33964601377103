import React from "react";
import "../styles/header.css";

const Distancer = ({ distance }) => {
  return (
    <div className="pageBreak" style={{ height: distance }}></div>
  );
};

export default Distancer;
