import "../App.css";
import Distancer from "../components/distancer";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Home = () => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const sections = ["data", "analysis", "decision", "execution"];

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    const hash = location.hash.replace("#", ""); // Remove the # to get the section ID
    const index = sections.indexOf(hash);
    setCurrentSectionIndex(index >= 0 ? index : -1); // Update the index or set to -1 if no match
  }, [location]);

  const getNextSection = () => {
    return currentSectionIndex >= 0 && currentSectionIndex < sections.length - 1
      ? `#${sections[currentSectionIndex + 1]}`
      : `#${sections[0]}`; // Wrap around to the first section if we're at the last
  };

  return (
    <div className="app">
      {" "}
      <div className="headlineBox">
        <span className="headline">Empowering</span>
        <span className="white headline"> Finance </span>
        <span className="headline">
          Through Innovation-Driven Software Solutions
        </span>
      </div>
      <div className="basicTextBox">
        {" "}
        <Distancer distance={"25vh"}></Distancer>
        <section id="data" key={`section-data`}>
          <Distancer distance={"25vh"}></Distancer>
          <span className="basicTitle">Data</span>
          <br></br>
          <br></br>
          <span className="basicText">
            At the heart of every successful endeavor lies data—the cornerstone
            of innovation and insight. At our company, we don’t just work with
            data; we live and breathe it. From finding the most obscure datasets
            to gathering them with precision, we ensure your data journey starts
            on solid ground. Once collected, we build secure, scalable solutions
            to store it, treating it as the invaluable asset it is. Most
            importantly, we protect your data with unwavering diligence,
            leveraging cutting-edge security measures to keep it safe. With us,
            your data isn’t just managed—it’s treasured.
          </span>
          <div className="basicImage dataImage"></div>
        </section>
        <Distancer distance={"25vh"}></Distancer>
        <section id="analysis" key={`section-execution`}>
          <Distancer distance={"25vh"}></Distancer>
          <span className="basicTitle">Analysis</span>
          <br></br>
          <br></br>
          <span className="basicText">
            Raw data holds untapped potential; our mission is to transform it
            into actionable insights. With a deep understanding of analysis
            techniques, we excel at filtering out noise, matching key patterns,
            and sorting meaningful trends. Whether you need to streamline
            operations, predict market behaviors, or uncover hidden
            opportunities, our analytical prowess ensures your data becomes a
            powerful decision-making tool. Every insight we deliver is a
            testament to our commitment to precision, efficiency, and clarity.
          </span>
          <div className="basicImage analysisImage"></div>
        </section>
        <Distancer distance={"25vh"}></Distancer>
        <section id="decision" key={`section-decision`}>
          <Distancer distance={"25vh"}></Distancer>
          <span className="basicTitle">Decision</span>
          <br></br>
          <br></br>
          <span className="basicText">
            Great decisions aren’t made on intuition alone—they’re backed by
            data. At our core, we believe in empowering businesses to make
            smarter, faster decisions through automation. By leveraging advanced
            algorithms and predictive models, we design systems that take the
            guesswork out of critical choices. These systems are built to learn,
            adapt, and act on real-time data, ensuring every decision is
            data-driven, optimized, and reliable. With us, you don’t just keep
            pace—you stay ahead of the curve.
          </span>
          <div className="basicImage decisionImage"></div>
        </section>
        <Distancer distance={"25vh"}></Distancer>
        <section id="execution" key={`section-execution`}>
          <Distancer distance={"25vh"}></Distancer>
          <span className="basicTitle">Execution</span>
          <br></br>
          <br></br>
          <span className="basicText">
            Ideas and insights mean little without flawless execution. This is
            where we shine. Our solutions are engineered for seamless
            integration, bridging gaps across systems and processes
            effortlessly. Robust and resilient, they are built to withstand
            challenges while remaining dynamic enough to evolve with your needs.
            From start to finish, we prioritize reliability, scalability, and
            performance to ensure every step of execution is a step toward
            success. With our expertise, execution isn’t just done—it’s
            perfected.
          </span>
          <div className="basicImage executionImage"></div>
        </section>
        <Distancer distance={"25vh"}></Distancer>
      </div>
      <a href={getNextSection()}>
        <div className="arrowdown"></div>
      </a>
    </div>
  );
};

export default Home;
