import React from "react";
import "../styles/header.css";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="header">
      <Link to="/" className="nav-link">
        <div alt="Logo" className="logo" />
      </Link>

      <nav className="header-nav">
        <Link to="/#data" className="nav-link">
          Data
        </Link>
        <Link to="/#analysis" className="nav-link">
          Analysis
        </Link>
        <Link to="/#decision" className="nav-link">
          Decision
        </Link>
        <Link to="/#execution" className="nav-link">
          Execution
        </Link>
      </nav>
    </header>
  );
};

export default Header;
